export const API_BASE_URL =
  import.meta.env.VITE_APP_API_BASE_URL || 'https://api.staging.trajaan.io/api';
export const CAPTCHA_KEY =
  import.meta.env.VITE_APP_CAPTCHA_KEY ||
  '6LfiC7MlAAAAAOZq74XDYKxeSibUdSDBpxuzKQ2N';
export const UNSPLASH_ACCESS_KEY =
  import.meta.env.VITE_APP_UNSPLASH_ACCESS_KEY ||
  'FdhOakQ4foWohoUFY00fB4daab5-NLU7_ZzguiAc90o';
export const APP_ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT || 'dev';
export const APP_VERSION = import.meta.env.VITE_APP_VERSION ?? 'dev';
