import './global.scss';
import './tailwind.css';
import 'maplibre-gl/dist/maplibre-gl.css';

import {lazy, Suspense} from 'react';

import {memo} from 'react';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';

const EmbeddedAnalytics = lazy(() => import('subApps/EmbeddedAnalytics'));
const AppRoot = lazy(() => import('subApps/AppRoot'));

function App() {
  return (
    <Router>
      <Suspense>
        <Switch>
          <Route exact path="/embedded-analytics">
            <EmbeddedAnalytics />
          </Route>
          <Route>
            <AppRoot />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default memo(App);
